import { articleId, paths } from '../../sources/constant';

import lightBulbImage from '../../assets/images/article-bulb-cover.jpg';
import anttiProfileImage from '../../assets/images/antti-thumbnail.jpg';
import articleLightBulbBulbs from '../../assets/images/article-lightbulb-bulbs.jpg';

const lightBulbArticle = {
	id: articleId.LIGHT_BULB,
	image: lightBulbImage,
	imageAlt: 'Light Bulb',
	author: 'Algebra and Dtal Technologies',
	authorImage: anttiProfileImage,
	date: '30 Aug 2024',
	href: paths.LIGHTBULB,
	en: {
		title: {
			first: 'Light',
			text: 'Bulb',
			translate: false
		},
		thumbnailTitle: 'Story of the light bulb',
		thumbnailDescription: 'The common knowledge tends to say that Edison invented the light bulb. We\'ll dig into this a bit and discuss who actually did inventing, what inventing means',
		content: [{
			html: ` <h4 class="page-title">Story of the light bulb</h4>
					<h5 class="subtitle">Light bulb is the icon of ideas, innovation. How did it come about?</h5>
					<p>The common knowledge tends to say that Edison invented the light bulb. We’ll dig into this a bit and discuss who actually did inventing, what inventing means. But first we need to add some clarity to what a light bulb is.</p>
					<h5 class="subtitle">Light Bulb</h5>
					<p>What makes a light bulb is the concept of using electricity into material that makes it glow, and placing that material in a vacuum so that the material does not oxidize while emitting light. To get any benefit from the light the vacuum enclosure should be transparent so that the light can get out and illuminate the surroundings.</p>
					<p>With these principle ideas in place, who got into this concept first?</p>
					<h5 class="subtitle">Patented Light Bulb</h5>
					<p>When Italian inventor Alessandro Volta developed his method of generating electricity in 1800 as part of his setup he also demonstrated the concept of incandescent lighting by making the copper wire connecting both ends of his battery glow. This put into motion a series of experimentation with ideas generating light from electricity. Very quickly there were trials with different materials instead of copper. As oxidization was an issue solutions were put in place to mitigate that by enclosing the light source in a glass cylinder either filled with a neutral gas or placed in vacuum. Yet producing vacuum those days was far from perfect leaving oxygen in ‘vacuum’. As an alternative concept arc light was tested using carbon electrodes. One concept that combined these threads was using carbon rods between electrodes placed in glass tube filled with nitrogen by Canadian inventors Henry Woodward and Matthew Evans.</p>
					<p>After the Canadian team had unsuccessfully tried to commercialize their lamps they sold their patent to Edison in 1879.</p>
					<h5 class="subtitle">From idea into innovation</h5>
					<p>The core ideas were all in place when Edison’s team started its work. Yet the concept was not commercially viable. Teresa Amabile calls innovation “successful implementation of creative ideas within an organization” as opposed to creativity that “produces novel and useful ideas in any domain”. Therefore creative ideas are raw material for innovation.</p>
					<div class="row img-wrapper">
						<div class="col-12 col-lg-11 mx-auto">
							<p><img src=${articleLightBulbBulbs} alt="lightings" class="img-fluid"/></p>
						</div>
					</div>

					<p>So Edison’s team had all these ideas available to them to get into a viable product. What the team did, they started extensive experimentation. In three years from 1878 to 1880 the team tested more than 3000 designs for bulbs. That makes almost 3 completed experiments for each day including holidays.</p>
					<p>Within this period towards the end of second year Edison filed patent for an electric lamp using carbon as light emitting material. Again Edison started experimenting to identify the right material, this time testing 6000 variations. Out of these the bamboo turned out to be the best.</p>
					<h5 class="subtitle">Innovation</h5>
					<p>Edison patented the first commercially successful bulb in 1879. The key words here are <strong>‘commercially successful’</strong> as that is what differentiated Edison’s patent from the other patents.</p>
					<p>There were many inventors with creative ideas, yet Edison’s team turned those into innovation – into successful implementation of ideas into products used commercially by the company and its customers. What method did Edison use to achieve these results that set him apart from others?</p>
					<h3 style="text-align: center; font-style: italic;">
					Focus extensively on value to customers
					</h3>
					<h3 style="text-align: center; font-style: italic; margin-bottom: 1em;">
					Use rapid iteration and experimentation
					</h3>
					<h5 class="subtitle">Edison’s method</h5>
					<p>Getting into this result was a lot of hard work. Was the work optimally done? Surely not. Were there un-necessary experiments? Absolutely. Did he have the most experienced scientists in his team? Probably not. With all these flaws how was he able to come apart from the rest?</p>
					<p>What Edison did was focus extensively on the value to customers and business and use rapid iteration and experimentation that gears learning into new heights. Build- measure-learn. Then build again. 3000 + 6000 times.</p>
					<p><span class="small">source:</span> <a href="http://www.livescience.com/43424-who-invented-the-light-bulb.html" class="small">http://www.livescience.com/43424-who-invented-the-light-bulb.html</a></p>`
		}]
	}
};

export default lightBulbArticle;
