import '../styles/HeroBanner.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Banner from '../components/Banner';

class HeroBannerComponent extends React.Component {
	static showFormattedMessage(id) {
		if (id) {
			return (
				<FormattedMessage
					id={id}
				/>
			);
		}
		return null;
	}

	constructor(props) {
		super(props);

		this.showFormattedMessage = HeroBannerComponent.showFormattedMessage;
	}

	render() {
		const { content } = this.props;
		const { image, title: { first, text, translate = true } = {} } = content || {};
		if (!content) return <div className="has-header container no-content" />;
		return (
			<div className="has-header container">
				<Banner className="screen-height" imgUrl={image}>
					<div className="hero-content overlay">
						<h1 className="hero-title">
							<span className="brown">
								{translate
									? this.showFormattedMessage(first)
									: first}
							</span>
							{' '}
							<span className="white">
								{translate
									? this.showFormattedMessage(text)
									: text}
							</span>
						</h1>
					</div>
				</Banner>
			</div>
		);
	}
}

HeroBannerComponent.propTypes = {
	content: PropTypes.shape({
		image: PropTypes.string,
		title: PropTypes.shape({
			first: PropTypes.string,
			text: PropTypes.string,
			translate: PropTypes.bool
		})
	})
};

HeroBannerComponent.defaultProps = {
	content: null
};

export default HeroBannerComponent;
